import { BaseElement, html, css, formCss } from 'Elements';

class MonitorClipboard extends BaseElement {

  static get styles() {
    return [
      formCss,
      css`        
      `
    ]
  }

  constructor() {
    super();
    this.debug = true;
    this.handlePaste = this.handlePaste.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    this._log.debug('start monitoring clipboard');
    document.addEventListener('paste', this.handlePaste);
  }


  disconnectedCallback() {
    super.disconnectedCallback();
    this._log.debug('stop monitoring clipboard');
    document.removeEventListener('paste', this.handlePaste);
  }

  async handlePaste(e) {
    e.preventDefault();
    const clipboardData = e.clipboardData;
    const items = clipboardData.items;

    // Vérifier tous les éléments dans le presse-papiers
    for (const item of items) {
      // Cas 1 : Le presse-papiers contient une image (fichier)
      if (item.type.startsWith('image/')) {
        const file = item.getAsFile();
        const imageUrl = URL.createObjectURL(file);
        console.log('Image collée directement :', imageUrl);

        // Affichage de l'image dans la page
        this.handleImage(imageUrl);
        return;
      }

      // Cas 2 : Le presse-papiers contient du texte (lien)
      if (item.type === 'text/plain') {
        item.getAsString((text) => {
          console.log('Texte collé :', text);
          if (/\.(jpeg|jpg|gif|png|webp|svg)$/i.test(text)) {
            this.handleImage(text);
          }
        });
      }
    }

    this._log.debug('No image found in clipboard');
  }

  isImageUrl(url) {
    return /\.(jpeg|jpg|gif|png|webp|svg|ico)$/i.test(url);
  }

  async handleImage(imageUrl) {
    this.modalImage = this.modalImage || this.qs('#modal-dialog-image');
    this.modalImage.show();
    this.modalImage.qs('image-bank').src = imageUrl;
  }

  render() {
    return html`
      <modal-dialog id="modal-dialog-image" width="90vw" height="90vh" label="Téléverser une image" close>
        <image-bank></image-bank>
        <sl-button slot="bt1" variant="text" close="true">Annuler</sl-button>
        <sl-button slot="bt2" variant="primary" @click="${this.doItemMove}">Enregistrer</sl-button>
      </modal-dialog>
    `
  }
}

customElements.define('monitor-clipboard', MonitorClipboard);
